<template>
  <div>
    <button
      :class="`button ${btnClass || 'w-full text-white font-bold text-m-btn md:text-base py-3 px-2.5'} ${disabled ? 'opacity-50' : ''}`"
      :type="type"
      :disabled="disabled"
      :style="style"
      @click="onClick"
    >
      <slot name="icon-left"></slot>
      {{ label }}
    </button>
  </div>
</template>

<script setup lang="ts">
import type { PropType, ButtonHTMLAttributes } from "vue";
defineProps({
	label: String,
  btnClass: String,
	disabled: Boolean,
	type: String as PropType<ButtonHTMLAttributes["type"]>,
	style: String,
});

const emit = defineEmits(["click"]);

const onClick = () => {
	emit("click");
};
</script>
